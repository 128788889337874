import React from 'react';
import { infiniteScroll } from '../../common/styles';

import DocumentItem from './DocumentItem';

const DocumentList = ({
  authUser,
  documents,
  onEditDocument,
  onRemoveDocument,
}) => (
  <div css={infiniteScroll}>
    {documents.map(document => (
      <DocumentItem
        authUser={authUser}
        key={document.id}
        document={document}
        onEditDocument={onEditDocument}
        onRemoveDocument={onRemoveDocument}
      />
    ))}
  </div>
);

export default DocumentList;
