import React, { Component, Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { css } from '@emotion/core';
import { Spinner } from '@reactivepad/blueprint-core';
import Loadable from 'react-loadable';
import { FONT_SIZES, PALETTE } from '../../common/styles';
import { getParamInPathname, withLocation } from '../../common/utils';
import { HOME } from '../../constants/routes';

const Editor = Loadable({
  loader: () => import('./Editor'),
  loading: () => <Spinner />,
});

const docHeaderStyles = css`
  width: 100%;
  padding: 14px 10px 10px 14px;
  border-bottom: none;
  z-index: 100;
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${PALETTE.gray2};
`;

const docTitleInputWrapperStyles = css`
  font-size: ${FONT_SIZES.h1};
  padding-top: 0;
  width: 70%;
  padding-right: 20px;
  margin-top: 5px;
`;

const docTitleInputStyles = css`
  float: left;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${PALETTE.black};
`;

const docWrapperStyles = css`
  height: 100%;
`;

const docStyles = css`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

class DocumentEditor extends Component {
  handleTitleChange = ({ target }) => {
    this.props.document.setTitle(target.value);
  };

  render() {
    const { document } = this.props;

    if (!document) {
      return null;
    }

    return (
      <Fragment>
        <div css={docHeaderStyles}>
          <span style={{ color: "#999" }}>title:</span>
          <div css={docTitleInputWrapperStyles}>
            <input
              css={docTitleInputStyles}
              value={document.title}
              onChange={this.handleTitleChange}
              maxLength={50}
            />
          </div>
        </div>
        <div css={docWrapperStyles}>
          <Box p={1} css={docStyles}>
            <Editor
              value={document.content}
              onChange={document.setContent}
              key={document.id}
            />
          </Box>
        </div>
      </Fragment>
    );
  }
}

export default compose(
  withLocation,
  inject('store'),
  mapProps(({ store, ...props }) => ({
    ...props,
    document: store.documentList.findDocumentById(
      getParamInPathname(props.location.pathname, HOME),
    ),
  })),
  observer,
)(DocumentEditor);
