import React, { Component } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { css } from '@emotion/core';
import { trimStart } from 'lodash-es';
import { navigate } from 'gatsby';
import { HOME } from '../../constants/routes';
import { PALETTE, FONT_SIZES } from '../../common/styles';
import {
  withLocation,
  isPathMatchingTokens,
} from '../../common/utils';

const documentStyles = isSelected =>
  css({
    width: '100%',
    padding: 15,
    borderBottom: `1px solid ${PALETTE.gray2}`,
    cursor: 'pointer',
    ...(isSelected && {
      backgroundColor: PALETTE.blue3,
    }),
  });

const nameStyles = css`
  font-size: ${FONT_SIZES.h1};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class DocumentItem extends Component {
  state = {
    isSelected: false,
  };

  static getDerivedStateFromProps({ document, location }) {
    return {
      isSelected: isPathMatchingTokens(location.pathname, [
        trimStart(HOME, '/'),
        document.id,
      ]),
    };
  }

  onDocumentClick = () => {
    const { isSelected } = this.state;
    const { document } = this.props;

    if (isSelected) {
      return;
    }

    navigate(`${HOME}/${document.id}`);
  };

  onDocumentDeleteClick = e => {
    e.preventDefault();
    e.stopPropagation();

    const { document } = this.props;

    document.remove();
    navigate(`${HOME}/`);
  };

  render() {
    const { document } = this.props;
    const { isSelected } = this.state;

    return (
      <div
        css={documentStyles(isSelected)}
        onClick={this.onDocumentClick}
      >
        <div css={nameStyles}>
          {document.title || <span className="i fw3">Untitled</span>}
          <span className="fr" onClick={this.onDocumentDeleteClick}>
            &#10005;
          </span>
        </div>
      </div>
    );
  }
}

export default compose(
  withLocation,
  observer,
)(DocumentItem);
