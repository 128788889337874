import React from 'react';
import { compose, mapProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { css } from '@emotion/core';
import { Box, Button, Divider } from '@material-ui/core';
import { AccountBox, Add, ArrowDropDown } from '@material-ui/icons';
import {
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
} from '@reactivepad/blueprint-core';
import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import { withFirebase } from '../components/Firebase';
import Documents from '../components/Documents';
import DocumentEditor from '../components/Editor/DocumentEditor';
import { PALETTE, scrollable } from '../common/styles';
import { HOME, ACCOUNT } from '../constants/routes';

const rootStyles = css`
  min-height: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${PALETTE.white};
`;

const appStyles = css`
  height: 100%;
  width: 100%;
  display: flex;
  vertical-align: top;
  overflow: hidden;
  position: relative;
`;

const documentListWrapperStyles = css`
  width: 350px;
  flex-grow: 0;
  padding-bottom: 0;
  height: 100%;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
`;

const documentListInnerWrapperStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  background-color: ${PALETTE.blue2};
  position: relative;
`;

const topSectionStyles = css`
  border-bottom: 1px solid ${PALETTE.gray2};
`;

const docWrapperStyles = css`
  flex: 1 50%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: ${PALETTE.white};
  color: ${PALETTE.black};
`;

const accountButtonLabelStyles = css`
  & .MuiButton-label {
    font-size: 1rem;
    justify-content: start;
  }
`;

const DocumentListSection = compose(
  withFirebase,
  inject('store'),
  mapProps(({ store, ...props }) => ({
    ...props,
    user: store.user,
    documentList: store.documentList,
  })),
  observer,
)(({ user, documentList, firebase }) => (
  <div css={documentListWrapperStyles}>
    <div css={documentListInnerWrapperStyles}>
      <Box p={2} css={topSectionStyles}>
        <Box mb={1}>
          <Popover
            open
            content={
              <Menu>
                <MenuItem
                  text="Account"
                  onClick={() => navigate(ACCOUNT)}
                  disabled={!user.email}
                />
                <MenuDivider />
                <MenuItem
                  text="Log out"
                  onClick={() => firebase.doSignOut()}
                />
              </Menu>
            }
          >
            <Button
              startIcon={<AccountBox />}
              endIcon={<ArrowDropDown />}
              fullWidth
              css={accountButtonLabelStyles}
              size="large"
            >
              {!user.email ? 'Demo account' : user.email}
            </Button>
          </Popover>
        </Box>
        <Divider />
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box fontWeight="fontWeightMedium">Documents</Box>
          <Button
            size="small"
            variant="outlined"
            startIcon={<Add />}
            onClick={async () => {
              const doc = await documentList.createAndAddDocument();
              navigate(`${HOME}/${doc.id}`);
            }}
          >
            New doc
          </Button>
        </Box>
      </Box>
      <div css={scrollable}>
        <AuthUserContext.Consumer>
          {authUser => <Documents authUser={authUser} />}
        </AuthUserContext.Consumer>
      </div>
    </div>
  </div>
));

const DocumentSection = () => (
  <div css={docWrapperStyles}>
    <DocumentEditor />
  </div>
);

const HomePageBase = () => (
  <div css={rootStyles}>
    <div css={appStyles}>
      <DocumentListSection />
      <DocumentSection />
    </div>
  </div>
);

const condition = authUser => !!authUser;

const HomePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePageBase);

export default () => (
  <Layout wholeScreen>
    <HomePage />
  </Layout>
);
