import React, { Component } from 'react';
import { compose, mapProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import { withFirebase } from '../Firebase';
import DocumentList from './DocumentList';

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      loading: [],
      documents: [],
      limit: 100,
    };
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  fetchDocuments = () => {
    const { authUser, documentList } = this.props;
    documentList.fetchDocuments(authUser.uid);
  };

  onChangeDocument = event => {
    this.setState({ content: event.target.value });
  };

  onCreateDocument = (event, authUser) => {
    event.preventDefault();

    this.props.firebase.userDocuments(this.props.authUser.uid).add({
      content: this.state.content,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ content: '' });
  };

  onEditDocument = (document, content) => {
    const { uid, ...documentSnapshot } = document;

    this.props.firebase
      .userDocument(this.props.authUser.uid, uid)
      .update({
        ...documentSnapshot,
        content,
        editedAt: this.props.firebase.fieldValue.serverTimestamp(),
      });
  };

  onRemoveDocument = uid => {
    this.props.firebase
      .userDocument(this.props.authUser.uid, uid)
      .remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForDocuments,
    );
  };

  render() {
    const { authUser, documentList } = this.props;
    const { documents, requestStatus } = documentList;

    if (requestStatus.isPending) {
      return <Box m={2}>Loading…</Box>;
    }

    return (
      <div>
        {documents && documents.length > 0 ? (
          <DocumentList
            authUser={authUser}
            documents={documents}
            onEditDocument={this.onEditDocument}
            onRemoveDocument={this.onRemoveDocument}
          />
        ) : (
          <Box m={2} textAlign="center">
            No documents at the moment.
          </Box>
        )}
      </div>
    );
  }
}

export default compose(
  withFirebase,
  inject('store'),
  mapProps(({ store, ...props }) => ({
    ...props,
    documentList: store.documentList,
  })),
  observer,
)(Documents);
